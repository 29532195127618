.card-slide-enter {
  background-size: cover;
  transform: translateY(-240%) translateX(300%);
  /* opacity: 0; */
}

.card-slide-enter-active {
  transform: translateY(0) translateX(0);
  opacity: 1;
  transition: transform 0.3s; 
  /* opacity 0.3s */
}
.card-slide-exit {
  opacity: 0;
  width: 0;
  overflow: hidden;
}

.card-slide-exit-active {
  opacity: 0;
  width: 0;
  transition: opacity 1ms, width 1ms;
  overflow: hidden;
}

.card-flip-enter {
  transform: rotateY(90deg);
  opacity: 0;
  backface-visibility: hidden;
}

.card-flip-enter-active {
  transform: rotateY(0deg);
  opacity: 1;
  transition: transform 0.5s, opacity 0.5s;  /* Add this line */
  backface-visibility: hidden;
}
.card-flip-exit {
  opacity: 0;
  width: 0;
  overflow: hidden;
}
.card-flip-exit-active {
  opacity: 0;
  width: 0;
  transition: opacity 1ms, width 1ms;
  overflow: hidden;
}
.texas-slide-enter {
  transform: translateY(-100%) translateX(100%);
  /* opacity: 0; */
}
.texas-slide-enter-active {
  transform: translateY(0) translateX(0);
  opacity: 1;
  transition: transform 0.5s; 
  /* opacity 0.3s */
}
.texas-slide-exit {
  opacity: 0;
  width: 0;
  overflow: hidden;
}

.texas-slide-exit-active {
  opacity: 0;
  width: 0;
  transition: opacity 1ms, width 1ms;
  overflow: hidden;
}



/* .card-inner {
  transform-style: preserve-3d;
  transition: transform 0.5s;
} */



.card-deal-enter {
    opacity: 0.01;
    transition-delay: 0.2s;
    /* Add your initial styles for the entering state */
  }
  
  .card-deal-enter.card-deal-enter-active {
    opacity: 1;
    transition: opacity 2s ease-in;
    transition-delay: 0.2s;
    /* Add the transition for the entering state */
  }
  
  .card-deal-exit {
    opacity: 1;
    transition-delay: 0.2s;
    /* Add your initial styles for the exiting state */
  }
  
  .card-deal-exit.card-deal-exit-active {
    opacity: 0.01;
    transition: opacity 1s ease-in;
    transition-delay: 0.2s;
    /* Add the transition for the exiting state */
  }
  






  