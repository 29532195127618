.refer-container {
  background: radial-gradient(circle at center, rgba(45, 45, 45, 0.95) 0%, rgba(26, 26, 26, 0.95) 100%) !important;
  border: 2px solid #efbf04 !important;
  color: hsla(39, 6.41%, 90%, 100%);
  padding: 2rem;
  border-radius: 12px;
  margin: 3rem auto;
  max-width: 1400px;
}

.refer-title {
  font-size: 2.8rem !important;
  font-weight: 900 !important;
  color: #efbf04;
  text-align: center;
  margin-bottom: 1rem;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
  margin-bottom: 1rem;
  user-select: none;
}

.refer-subtitle {
  font-size: 1.5rem !important;
  text-align: center;
  margin-bottom: 2rem !important;
  color: #efbf04;
  font-weight: 600 !important;
  letter-spacing: 0.03em !important;
  user-select: none;
}

.refer-link-container {
  background-image: rgba(26, 26, 26, 0.95);
  border: 2px solid #efbf04;
  padding: 1rem;
  border-radius: 8px;
  margin: 0 auto;
  max-width: 600px;
}

.tier-header {
  margin-bottom: 1rem;
}

.tier-name {
  font-size: 2.5rem !important;
  font-weight: 900 !important;
  color: hsla(39, 6.41%, 98%, 100%);
  text-align: center;
  margin-bottom: 1rem;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
  user-select: none;
}

.tier-deposits {
  font-size: 1.2rem !important;
  font-weight: 700 !important;
  color: hsla(39, 6.41%, 98%, 100%);
  text-align: center;
  margin-bottom: 2rem;
  letter-spacing: 0.05em;
  text-transform: none;
  font-family: monospace;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
  user-select: none;
}

.tier-commission {
  background-color: hsla(24, 12.82%, 15%, 90%);
  padding: 1.5rem;
  border-radius: 8px;
  text-align: center;
  margin: 1.5rem 0;
  user-select: none;
}

.commission-rate {
  color: hsla(120, 100%, 45%, 100%);
  font-size: 2.75rem !important;
  font-weight: 900 !important;
  margin-bottom: 0.5rem !important;
  text-shadow: 0 0 10px hsla(120, 100%, 45%, 30%);
  font-family: monospace;
  user-select: none;
}

.commission-label {
  color: hsla(39, 6.41%, 80%, 100%);
  font-size: 1rem !important;
  font-weight: 800 !important;
  text-transform: uppercase !important;
  letter-spacing: 0.15em !important;
  user-select: none;
}

.tier-requirements {
  margin-top: 1rem;
}

.requirement-item {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1.5rem 0;
  gap: 0.75rem;
  text-align: center;
}

.requirement-item .MuiTypography-root {
  color: hsla(39, 6.41%, 90%, 100%);
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.4;
  font-family: monospace;
  letter-spacing: 0.05em;
  user-select: none;
}

.check-icon {
  color: hsla(120, 100%, 45%, 100%);
  font-size: 1.5rem;
  user-select: none;
}

@media (max-width: 1024px) {
  .tiers-grid {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 640px) {
  .tiers-grid {
    grid-template-columns: 1fr;
  }
}

.highlights-container {
  max-width: 1200px;
  margin: 2rem auto;
  padding: 0 1rem;
}

.highlights-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr) auto;
  gap: 1rem;
  margin-bottom: 2rem;
}

.highlight-card {
  background-image: rgba(26, 26, 26, 1);
  border: 2px solid #efbf04;
  padding: 1rem;
  border-radius: 8px;
  display: flex;
  gap: 1rem;
}

.highlight-icon {
  background-image: rgba(26, 26, 26, 1);
  width: 48px;
  height: 48px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #e0e0e0;
}

.highlight-content {
  flex: 1;
}

.highlight-label {
  color: #e0e0e0;
  font-size: 0.9rem;
  margin-bottom: 0.25rem;
  user-select: none;
}

.highlight-value {
  font-size: 1.5rem;
  font-weight: 700;
  color: hsla(39, 6.41%, 90%, 100%);
  margin-bottom: 0.25rem;
  margin-top: 0.25rem;
  user-select: none;
}

.highlight-change {
  font-size: 0.8rem;
  user-select: none;
}

.highlight-change.positive {
  color: hsla(120, 100%, 45%, 100%);
}

.highlight-change.negative {
  color: hsla(0, 100%, 65%, 100%);
}

.funds-card {
  margin: 1rem auto 2rem;
  border-radius: 12px;
  padding: 1rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 320px;
  background-image: rgba(26, 26, 26, 1);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  position: relative;
  cursor: pointer;
  user-select: none;
  border: 2px solid #efbf04;
}

.funds-content {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  transition: all 0.3s ease;
}

.funds-card:hover .funds-content {
  filter: blur(2px);
  opacity: 0.3;
}

.funds-info {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
  align-items: center;
  text-align: center;
}

.funds-label {
  font-size: 1.2rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  color: #e0e0e0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 3rem !important;
  user-select: none;
}

.value-with-coin {
  font-size: 1.6rem;
  font-weight: 800;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.75rem;
  user-select: none;
}

.claim-button {
  background-color: hsla(120, 40%, 50%, 100%) !important;
  color: white !important;
  padding: 0.5rem 2rem !important;
  user-select: none;
}

.tier-status {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2rem;
  padding: 1rem;
  background-image: radial-gradient(circle at center, rgba(45, 45, 45, 0.95) 0%, rgba(26, 26, 26, 0.95) 100%);
  border-radius: 8px;
  border: 2px solid #efbf04;
  user-select: none;
}

.current-tier {
  color: hsla(45, 50%, 50%, 100%);
  font-weight: 700;
  user-select: none;
}

.view-tiers-button {
  margin-top: 1.5rem !important;
  background-color: hsla(204, 19.23%, 40%, 100%) !important;
  color: hsla(39, 6.41%, 90%, 100%) !important;
  text-transform: uppercase !important;
  font-weight: 700 !important;
  padding: 0.75rem !important;
  border-radius: 8px !important;
  letter-spacing: 0.05em !important;
  font-size: 1rem !important;
  width: 100% !important;
  user-select: none;
}

.tiers-dialog {
  .MuiDialog-paper {
    background-image: radial-gradient(circle at center, rgba(45, 45, 45, 0.95) 0%, rgba(26, 26, 26, 0.95) 100%);
    padding: 2rem;
    border: 2px solid #efbf04;
  }
}

.dialog-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-bottom: 1px solid #efbf04;
  margin-bottom: 2rem;
  user-select: none;
}

.dialog-header h6 {
  font-size: 1.5rem;
  font-weight: 900;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  user-select: none;
}

.current-tier-section {
    margin-top: 2rem;
  }
  
  .current-tier-box {
    max-width: 400px;
    margin: 0 auto;
    cursor: pointer;
    user-select: none;
  }
  
  .current-tier-box .view-tiers-button {
    margin-top: 1.5rem;
    background-color: hsla(204, 19.23%, 40%, 100%) !important;
    color: hsla(39, 6.41%, 90%, 100%) !important;
    text-transform: uppercase;
    font-weight: 600;
    padding: 0.75rem !important;
    border-radius: 8px;
    user-select: none;
  }
  
  .current-tier-box .view-tiers-button:hover {
    background-color: hsla(204, 19.23%, 45%, 100%) !important;
}

@media (max-width: 1024px) {
  .highlights-grid {
    grid-template-columns: repeat(2, 1fr);
  }
  
  .funds-card {
    grid-column: span 2;
  }
}

@media (max-width: 640px) {
  .highlights-grid {
    grid-template-columns: 1fr;
  }
  
  .funds-card {
    grid-column: span 1;
  }
}

.value-with-coin {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #05f783;
  font-weight: 700;
}

.highlight-value .value-with-coin {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
}

.funds-value .value-with-coin {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
}

/* Make sure MUI styles are overridden */
.MuiTypography-root {
  font-family: inherit !important;
}

.StyledTierBox {
  padding: 2rem !important;
}

/* Main container styles */
.refer-dialog {
  background-image: radial-gradient(circle at center, rgba(45, 45, 45, 0.95) 0%, rgba(26, 26, 26, 0.95) 100%);
  padding: 2rem;
  border: 2px solid #efbf04;
}

.refer-dialog-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0 1.5rem 0;
  margin-bottom: 2rem;
  border-bottom: 1px solid #efbf04;
}

.refer-dialog-title {
  font-size: 1.5rem;
  font-weight: 900;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: #e0e0e0;
}

.refer-dialog-close {
  color: #e0e0e0;
  padding: 8px;
}

/* Tier grid styles */
.refer-tiers-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  padding: 0;
  width: 100%;
  margin: 0 auto;
}

/* Individual tier styles */
.refer-tier-name {
  font-size: 2.5rem !important;
  font-weight: 900 !important;
  color: hsla(39, 6.41%, 95%, 100%);
  text-align: center;
  margin-bottom: 1rem;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
}

.refer-tier-deposits {
  color: hsla(39, 6.41%, 90%, 100%);
  font-size: 1.2rem !important;
  font-weight: 700 !important;
  text-align: center;
  margin-bottom: 2rem;
  letter-spacing: 0.05em;
  font-family: monospace;
}

.refer-tier-commission {
  background-image: radial-gradient(circle at center, rgba(45, 45, 45, 0.95) 0%, rgba(26, 26, 26, 0.95) 100%);
  padding: 1.5rem;
  border-radius: 8px;
  border: 2px solid #efbf04;
  text-align: center;
  margin: 1.5rem 0;
}

.refer-commission-rate {
  color: hsla(120, 100%, 45%, 100%);
  font-size: 2.75rem !important;
  font-weight: 900 !important;
  margin-bottom: 0.5rem !important;
  text-shadow: 0 0 10px hsla(120, 100%, 45%, 30%);
  font-family: monospace;
}

.refer-commission-label {
  color: hsla(39, 6.41%, 80%, 100%);
  font-size: 1rem !important;
  font-weight: 800 !important;
  text-transform: uppercase;
  letter-spacing: 0.15em !important;
}

.refer-requirement {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1.5rem 0;
  gap: 0.75rem;
  text-align: center;
}

.refer-requirement-text {
  color: hsla(39, 6.41%, 90%, 100%);
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.4;
  font-family: monospace;
  letter-spacing: 0.05em;
}

.refer-check-icon {
  color: hsla(120, 100%, 45%, 100%);
  font-size: 1.5rem;
}
  

/* Responsive styles */
@media (max-width: 1024px) {
  .refer-tiers-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 640px) {
  .refer-tiers-grid {
    grid-template-columns: 1fr;
  }
}

.claim-hover-text {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.3rem;
  font-weight: 800;
  color: #05f783;
  opacity: 0;
  transition: all 0.3s ease;
  text-shadow: 0 0 10px rgba(5, 247, 131, 0.3);
  z-index: 2;
  cursor: pointer;
}

.funds-card:hover .claim-hover-text {
  opacity: 1;
  transform: translate(-50%, -50%) scale(1.1);  /* Slight pop effect */
}
