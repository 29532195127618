.fairness-container {
  background: linear-gradient(145deg, #1a1a1a 0%, #2d2d2d 100%) !important;
  color: #e0e0e0;
  padding: 2rem;
  border-radius: 12px;
  margin-top: 3rem;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  border: 2px solid #efbf04;
}

.fairness-title {
  font-size: 2.5rem;
  font-weight: 700;
  color: #efbf04;
  text-align: center;
  margin-bottom: 2rem;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

.fairness-subtitle {
  font-size: 1.75rem;
  font-weight: 700;
  color: #efbf04;
  margin-bottom: 1.5rem;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

.fairness-list {
  margin-bottom: 3rem;
}

.fairness-item {
  display: flex;
  align-items: center;
  padding: 1.5rem;
  background-color: rgba(45, 45, 45, 0.9) !important;
  border-radius: 8px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border: 1px solid #efbf04;
}

.fairness-icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  background-color: #efbf04;
  border-radius: 50%;
  margin-right: 1rem;
}

.fairness-icon {
  font-size: 1.5rem;
  color: #000;
}

.fairness-text {
  font-size: 1.1rem;
  font-weight: 500;
  color: #e0e0e0;
}

.fairness-section {
  margin-bottom: 3rem;
}

.fairness-game-select {
  width: 100%;
  margin-bottom: 1.5rem;
  background-color: rgba(45, 45, 45, 0.9) !important;
  color: #efbf04;
  border: 1px solid #efbf04;
  border-radius: 8px;
}

.fairness-game-info {
  background-color: rgba(45, 45, 45, 0.9) !important;
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid #efbf04;
}

.fairness-game-info .MuiTableHead-root {
  background-color: rgba(26, 26, 26, 0.95) !important;
}

.fairness-game-info .MuiTableCell-head {
  color: #efbf04;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 0.9rem;
  letter-spacing: 0.05em;
}

.fairness-game-info .MuiTableCell-body {
  color: #e0e0e0;
  font-size: 0.95rem;
  font-weight: 500;
}

.fairness-game-info .MuiTableCell-root {
  padding: 12px 16px;
  border-bottom: 1px solid rgba(239, 191, 4, 0.2) !important;
}

.fairness-game-info .MuiTableRow-root td:nth-child(1),
.fairness-game-info .MuiTableRow-root td:nth-child(2) {
  font-weight: 600;
  color: #efbf04;
}

.fairness-game-info .MuiTableRow-root td:nth-child(3),
.fairness-game-info .MuiTableRow-root td:nth-child(4),
.fairness-game-info .MuiTableRow-root td:nth-child(5) {
  font-family: monospace;
  font-size: 0.85rem;
  letter-spacing: 0.05em;
  color: #e0e0e0;
}

.fairness-copy-wrapper {
  position: relative;
  display: inline-block;
}

.fairness-copy-btn {
  color: #efbf04;
  cursor: pointer;
  transition: color 0.3s ease;
}

.fairness-copy-btn:hover {
  color: #ffd700;
}

.fairness-copy-success {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #efbf04;
  color: #000;
  padding: 2px 4px;
  border-radius: 4px;
  font-weight: 700;
  font-size: 1.25rem;
  white-space: nowrap;
}

.fairness-verify-btn {
  background-color: #efbf04;
  color: #000;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0.05em;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.fairness-verify-btn:hover {
  background-color: #ffd700;
}