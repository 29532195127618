.keno-payout-container {
    width: 100%;
    max-width: 800px;
    background-color: #1c1f24;
    border-radius: 10px;
    padding: 10px 16px;
    border: 2px solid #efbf04;
}

.payout-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    padding-bottom: 10px;
    border-bottom: 1px solid #333;
}

.payout-header span {
    color: #fff;
    font-size: 1.1em;
}

.selected-count {
    color: #ffd700 !important;
}

.payout-content {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-wrap: nowrap;
    overflow-x: auto;
}

.payout-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #2a2d33;
    padding: 8px 15px;
    border-radius: 8px;
    min-width: 70px;
    
}

.hits {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 1.1rem;
    margin-bottom: 4px;
    font-weight: 700;
    white-space: nowrap;
    align-items: center;
    gap: 4px;
}

.multiplier {
    color: #00e676;
    font-weight: bold;
}

/* Hide scrollbar but keep functionality */
.payout-content {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.payout-content::-webkit-scrollbar {
    display: none;
}

.select-numbers-message {
    color: #efbf04;
    text-align: center;
    padding: 18px;
    font-size: 1.1em;
    height: 70px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .payout-content {
        gap: 6px;
    }

    .payout-item {
        min-width: 60px;
        padding: 6px 12px;
    }

    .hits {
        font-size: 0.9rem;
        gap: 3px;
    }

    .multiplier {
        font-size: 0.9rem;
    }
}

@media (max-width: 480px) {
    .payout-content {
        gap: 4px;
    }

    .payout-item {
        min-width: 50px;
        padding: 4px 8px;
    }

    .hits {
        font-size: 0.8rem;
        gap: 2px;
    }

    .multiplier {
        font-size: 0.8rem;
    }
}
