.winnings-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #0a0f1c; /* Dark background color */
    z-index: 1000; /* Ensure it's above other elements */
  }
  
  .winnings-box {
    background-color: rgba(34, 34, 34, 0.9); /* Semi-transparent background */
    border: 4px solid #00ff00; /* Green border */
    border-radius: 15px; /* Slightly more rounded corners */
    padding: 30px 50px; /* Increase padding */
    text-align: center;
    box-shadow: 0 0 20px rgba(0, 255, 0, 0.3); /* Green glow effect */
    animation: pulse 2s infinite; /* Add a subtle pulse animation */
  }
  
  .multiplier-text {
    font-size: 64px; /* Larger font size */
    color: #00ff00; /* Green text color */
    font-weight: bold;
    text-shadow: 0 0 10px rgba(0, 255, 0, 0.5); /* Text glow effect */
  }
  
  .divider {
    width: 100%;
    height: 2px;
    background-color: #3c3f45; /* Divider color */
    margin: 15px 0; /* Increase margin */
  }
  
  .winnings-text {
    font-size: 32px; /* Larger font size */
    color: #00ff00; /* Green text color */
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
  }

@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
}

/* Add fade-in and fade-out animations */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes fadeOut {
  from { opacity: 1; }
  to { opacity: 0; }
}

.fade-in {
  animation: fadeIn 0.5s ease-in;
}

.fade-out {
  animation: fadeOut 0.5s ease-out;
}