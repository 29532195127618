.homepage-toggle-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 800px; /* Increased width */
    height: 40px;
    border: 2px solid #efbf04;
    background-color: rgba(26, 26, 26, 0.9);
    border-radius: 20px;
    margin-bottom: 24px;
  }
  
  .homepage-toggle-container input[type="radio"] {
    display: none;
  }
  
  .homepage-toggle-container label {
    flex: 1;
    text-align: center;
    line-height: 40px;
    color: #fff;
    font-weight: bold;
    z-index: 1;
    cursor: pointer;
    text-transform: capitalize;
    white-space: nowrap;
    user-select: none;
  }
  
  .homepage-toggle-slider {
    position: absolute;
    top: 3px;
    left: 3px;
    width: calc(25% - 6px);
    height: calc(100% - 6px);
    background-color: #efbf04;
    border-radius: 20px;
    transition: left 0.3s;
  }
  
  /* Positioning for different numbers of options */
  .homepage-toggle-container input[type="radio"]:nth-of-type(1):checked ~ .homepage-toggle-slider {
    left: 3px;
  }
  
  .homepage-toggle-container input[type="radio"]:nth-of-type(2):checked ~ .homepage-toggle-slider {
    left: calc(25% + 3px);
  }
  
  .homepage-toggle-container input[type="radio"]:nth-of-type(3):checked ~ .homepage-toggle-slider {
    left: calc(50% + 3px);
  }
  
  .homepage-toggle-container input[type="radio"]:nth-of-type(4):checked ~ .homepage-toggle-slider {
    left: calc(75% + 3px);
  }
  
  /* Responsive width */
  @media (max-width: 1024px) {
    .homepage-toggle-container {
      width: 600px;
    }
  }
  
  @media (max-width: 768px) {
    .homepage-toggle-container {
      width: 400px;
    }
  }
  
  @media (max-width: 480px) {
    .homepage-toggle-container {
      width: 300px;
    }
  }