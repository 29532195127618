.exchange-toggle-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 400px;
    height: 40px;
    background-image: radial-gradient(circle at center, rgba(45, 45, 45, 0.95) 0%, rgba(26, 26, 26, 0.95) 100%);
    border: 2px solid #efbf04;
    border-radius: 20px;
    margin: 20px auto;
}

.exchange-toggle-container input[type="radio"] {
    display: none;
}

.exchange-toggle-container label {
    flex: 1;
    text-align: center;
    line-height: 40px;
    color: #e0e0e0;
    z-index: 1;
    cursor: pointer;
    text-transform: capitalize;
    white-space: nowrap;
    font-weight: bold;
    user-select: none;
}

.exchange-toggle-container .toggle-slider {
    position: absolute;
    top: 3px;
    left: 3px;
    width: calc(50% - 6px);
    height: calc(100% - 6px);
    background-color: #efbf04;
    border-radius: 20px;
    transition: left 0.3s;
}

.exchange-toggle-container input[type="radio"]:nth-of-type(1):checked ~ .toggle-slider {
    left: 3px;
}

.exchange-toggle-container input[type="radio"]:nth-of-type(2):checked ~ .toggle-slider {
    left: calc(50% + 3px);
}

.exchange-grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    gap: 20px;
    padding: 20px;
}

.exchange-grid-item {
    background-image: radial-gradient(circle at center, rgba(45, 45, 45, 0.95) 0%, rgba(26, 26, 26, 0.95) 100%);
    border: 2px solid #efbf04;
    border-radius: 8px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease;
}

.exchange-grid-item:hover {
    box-shadow: 0 4px 8px rgba(239, 191, 4, 0.2);
}

.exchange-grid-item-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
    font-size: 0.9rem;
}

.exchange-grid-item-id {
    font-weight: bold;
    color: #efbf04;
}

.exchange-grid-item-date {
    color: #e0e0e0;
}

.exchange-grid-item-amount {
    font-size: 1.2rem;
    font-weight: bold;
    color: #efbf04;
    margin-bottom: 12px;
}

.exchange-grid-item-status-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: auto;
}

.exchange-grid-item-status {
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 0.8rem;
    font-weight: bold;
    text-transform: capitalize;
}

.status-pending {
    background-color: #efbf04;
    color: #000;
}

.status-claimed {
    background-color: #efbf04;
    color: #000;
}

.status-completed {
    background-color: #efbf04;
    color: #000;
}

.claim-button {
    background-color: #efbf04;
    color: #000;
    border: none;
    padding: 5px 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 0.8rem;
    margin-left: 8px;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s ease;
}

.claim-button:hover {
    background-color: #d4a803;
}

.exchange-grid-item-usd-amount {
    font-size: 0.9rem;
    color: #e0e0e0;
    margin-bottom: 8px;
}

/* Deposit/Withdrawal Toggle */
.deposit-withdrawal-toggle {
    display: inline-flex;
    background-image: radial-gradient(circle at center, rgba(45, 45, 45, 0.95) 0%, rgba(26, 26, 26, 0.95) 100%);
    border: 2px solid #efbf04;
    border-radius: 6px;
    padding: 3px;
    width: 240px;
    margin: 20px auto;
    position: relative;
}

.deposit-withdrawal-toggle input[type="radio"] {
    display: none;
}

.deposit-withdrawal-toggle label {
    flex: 1;
    text-align: center;
    line-height: 32px;
    color: #e0e0e0;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 14px;
    user-select: none;
    transition: color 0.3s ease;
    position: relative;
    z-index: 1;
}

.deposit-withdrawal-toggle .toggle-slider {
    position: absolute;
    top: 3px;
    left: 3px;
    width: calc(50% - 3px);
    height: calc(100% - 6px);
    background-color: #efbf04;
    border-radius: 4px;
    transition: transform 0.3s ease;
}

.deposit-withdrawal-toggle input[type="radio"]:checked + label {
    color: #000;
}

.deposit-withdrawal-toggle input[type="radio"]:nth-of-type(2):checked ~ .toggle-slider {
    transform: translateX(100%);
}

/* Create Request Button */
.create-request-button {
    display: block;
    margin: 20px auto;
    padding: 10px 20px;
    background-color: #efbf04;
    color: #000;
    border: none;
    border-radius: 6px;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.create-request-button:hover {
    background-color: #d4a803;
}

/* Mobile Styles */
@media (max-width: 600px) {
    .exchange-toggle-container {
        width: 90%;
        max-width: 300px;
    }

    .deposit-withdrawal-toggle {
        width: 90%;
        max-width: 240px;
    }

    .exchange-grid-container {
        grid-template-columns: 1fr;
        padding: 10px;
    }

    .exchange-grid-item {
        padding: 12px;
    }

    .exchange-grid-item-header {
        flex-direction: column;
        align-items: flex-start;
    }

    .exchange-grid-item-date {
        margin-top: 4px;
    }

    .exchange-grid-item-amount {
        font-size: 1rem;
    }

    .exchange-grid-item-status-container {
        flex-direction: column;
        align-items: flex-start;
    }

    .exchange-grid-item-status {
        margin-bottom: 8px;
    }

    .claim-button {
        width: 100%;
        margin-left: 0;
        margin-top: 8px;
    }
}

/* MUI Component Overrides */
.MuiButton-root {
    background-color: #efbf04;
    color: #000;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 0.05em;
    padding: 8px 16px;
    border-radius: 4px;
}

.MuiButton-root:hover {
    background-color: #d4a803;
}

.MuiMenu-paper {
    background-image: radial-gradient(circle at center, rgba(45, 45, 45, 0.95) 0%, rgba(26, 26, 26, 0.95) 100%);
    border: 2px solid #efbf04;
}

.MuiMenuItem-root {
    color: #e0e0e0;
}

.MuiMenuItem-root.Mui-selected {
    background-color: #efbf04;
    color: #000;
}

.MuiMenuItem-root:hover {
    background-color: rgba(239, 191, 4, 0.2);
}