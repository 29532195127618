.palm {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 40px;
  padding: 20px;
  min-height: 40vh;
}

.palm__game_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: translateY(30px);
}

.palm__game_board {
  position: relative;
  width: 400px;
  background: transparent;
  padding: 12px;
}

.palm__dragon_header {
  position: absolute;
  top: -60px;
  left: 50%;
  transform: translateX(-50%);
  width: 120px;
  height: 80px;
  z-index: 2;
}

.palm__tower_grid {
  display: grid;
  gap: 6px;
  padding: 10px;
  height: 520px;
  width: 100%;
}

.palm__tower_grid.cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-template-rows: repeat(8, 60px);
}

.palm__tower_grid.cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-template-rows: repeat(8, 60px);
}

.palm__tower_grid.cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-template-rows: repeat(8, 60px);
}

.palm__tower_cell {
  background: rgba(139, 69, 19, 0.9);
  border: 2px solid rgba(160, 82, 45, 0.5);
  border-radius: 4px;
  cursor: default;
  transition: all 0.3s ease;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.palm__tower_cell.current {
  cursor: pointer;
  background: goldenrod;
  animation: goldenPulse 1s ease-in-out infinite;
}
.palm__tower_cell.current:hover {
  background-color: rgba(255, 215, 0, 0.2);
}

.palm__tower_cell.revealed {
  animation: revealCell 0.3s ease forwards;
}

.palm__tower_cell.revealed.gem {
  background: #4CAF50;
}

.palm__tower_cell.revealed.mine {
  background: #f44336;
}

.palm__tower_icon {
  width: 42px;
  height: 42px;
  object-fit: contain;
}

.palm__tower_coconut {
  filter: drop-shadow(0 0 6px rgba(255, 255, 255, 0.8));
  animation: coconutReveal 0.5s ease-out forwards, coconutGlow 2s infinite;
}

.palm__tower_axe {
  color: #8B0000;
  filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.5));
  transform: rotate(-45deg);
  font-size: 24px;
  animation: axeReveal 0.3s ease-out forwards;
}

.palm__tower_cell.revealed .palm__tower_axe {
  animation: axeReveal 1s ease-out;
}

@keyframes axeReveal {
  0% {
    transform: rotate(0deg) scale(0.8);
    opacity: 0;
  }
  100% {
    transform: rotate(-45deg) scale(1);
    opacity: 1;
  }
}

@keyframes revealCell {
  0% {
    transform: scale(0.8);
    opacity: 0.5;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.palm__tower_cell::before {
  content: '';
  position: absolute;
  inset: 0;
  background: linear-gradient(
    90deg,
    transparent 0%,
    rgba(222, 184, 135, 0.1) 25%,
    rgba(222, 184, 135, 0.1) 35%,
    transparent 60%
  );
  background-size: 200% 100%;
}

/* Coordinated animations for different columns */
.palm__tower_grid.cols-2 .palm__tower_cell:nth-child(2n + 1)::before {
  animation: shimmer 2.5s infinite linear;
}
.palm__tower_grid.cols-2 .palm__tower_cell:nth-child(2n + 2)::before {
  animation: shimmer 2.5s infinite linear 0.3s;
}

.palm__tower_grid.cols-3 .palm__tower_cell:nth-child(3n + 1)::before {
  animation: shimmer 2.5s infinite linear;
}
.palm__tower_grid.cols-3 .palm__tower_cell:nth-child(3n + 2)::before {
  animation: shimmer 2.5s infinite linear 0.3s;
}
.palm__tower_grid.cols-3 .palm__tower_cell:nth-child(3n + 3)::before {
  animation: shimmer 2.5s infinite linear 0.6s;
}

.palm__tower_grid.cols-4 .palm__tower_cell:nth-child(4n + 1)::before {
  animation: shimmer 2.5s infinite linear;
}
.palm__tower_grid.cols-4 .palm__tower_cell:nth-child(4n + 2)::before {
  animation: shimmer 2.5s infinite linear 0.3s;
}
.palm__tower_grid.cols-4 .palm__tower_cell:nth-child(4n + 3)::before {
  animation: shimmer 2.5s infinite linear 0.6s;
}
.palm__tower_grid.cols-4 .palm__tower_cell:nth-child(4n + 4)::before {
  animation: shimmer 2.5s infinite linear 0.9s;
}

@keyframes shimmer {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}

.palm__tower_cell.active {
  background-color: #4CAF50;
}

.palm__tower_cell.inactive {
  opacity: 0.5;
  cursor: not-allowed;
  background-color: #4CAF50;
}

.cashout-button {
  padding: 10px 20px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.cashout-button:hover {
  background-color: #45a049;
}

@media (max-width: 768px) {
  .palm__tower_icon {
    width: 24px;
    height: 24px;
  }
  .palm__tower_axe {
    font-size: 20px;
  }
}

@keyframes goldenPulse {
  0% {
    box-shadow: 0 0 5px rgba(255, 215, 0, 0.3);
    outline-color: rgba(255, 215, 0, 0.3);
  }
  50% {
    box-shadow: 0 0 15px rgba(255, 215, 0, 0.6);
    outline-color: rgba(255, 215, 0, 0.8);
  }
  100% {
    box-shadow: 0 0 5px rgba(255, 215, 0, 0.3);
    outline-color: rgba(255, 215, 0, 0.3);
  }
}

@keyframes coconutReveal {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes coconutGlow {
  0% {
    filter: drop-shadow(0 0 8px rgba(255, 255, 255, 0.6));
  }
  50% {
    filter: drop-shadow(0 0 12px rgba(255, 255, 255, 0.9));
  }
  100% {
    filter: drop-shadow(0 0 8px rgba(255, 255, 255, 0.6));
  }
}

/* Updated selection styles */
.palm__tower_cell.selected {
  scale: 1.02;
  border: 4px solid rgb(0, 132, 255);

}

.palm__tower_cell.axe-selected {
  scale: 1.02;
  border: 4px solid #c7062c;
}

/* Optional: Add hover effect for unclicked tiles */
/* .palm__tower_cell:not(.revealed):hover {
  transform: scale(1.05) translateY(-3px);
  box-shadow: 0 1px 8px #fbfdc1;
} */

.palm__tower_cell.auto-selected {
  scale: 1.02;
  background-color: rgba(255, 215, 0, 0.2);
}

.palm__tower_cell.shake-animation {
  animation: shake 0.3s ease-in-out;
}
.palm__tower_cell.auto-selectable {
  cursor: pointer;
}
.palm__tower_cell.auto-selectable:hover {
  background-color: rgba(255, 215, 0, 0.2); /* Light gold background on hover */
}

@keyframes shake {
  0%, 100% { transform: translateX(0); }
  25% { transform: translateX(-5px); }
  75% { transform: translateX(5px); }
}
