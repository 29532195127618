.flower-poker-container {
    font-family: Arial, sans-serif;
    background-color: #2c2c2c;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px;
    border-radius: 10px;
    min-width: 600px;
  }
  
  .flower-poker-container h1 {
    margin-bottom: 20px;
  }
  
  .flower-poker-container .grid {
    display: flex;
    justify-content: space-between;
  }
  
  .flower-poker-container .column {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .flower-poker-container .column h2 {
    margin-bottom: 10px;
  }
  
  .flower-poker-container .cell {
    width: 80px;
    height: 80px;
    background-color: #333;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin: 5px 0;
  }
  
  .flower-poker-container .results {
    margin-top: 20px;
    text-align: center;
  }
  
  .flower-poker-container .results div {
    margin: 5px 0;
  }
  
  .flower-poker-container .results button {
    padding: 10px 20px;
    background-color: #4caf50;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 10px;
  }
  
  .flower-poker-container .results button:hover {
    background-color: #45a049;
  }
  
  /* @keyframes fpPulse {
  0% {
    transform: scale(1);
    box-shadow: 0 0 0px rgba(255, 255, 0, 0);
  }
  50% {
    transform: scale(1.05);
    box-shadow: 0 0 8px #fbfdc1;
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 0px rgba(255, 255, 0, 0);
  }
}

.fpPulse {
  animation: fpPulse 2s infinite;

} */

.flower-poker-button {
  @apply mt-4 px-4 py-2 bg-green-500 text-white rounded-md transition duration-300 ease-in-out w-32 flex justify-center items-center;
}

.flower-poker-button:disabled {
  @apply opacity-50 cursor-not-allowed;
}

@media (hover: hover) and (pointer: fine) {
  .flower-poker-button:hover:not(:disabled) {
    @apply bg-green-600 scale-105;
  }
}

.flower-poker-button:active:not(:disabled) {
  @apply bg-green-600 scale-105;
}

@keyframes flowerGrow {
  0% {
    transform: scale(0.1);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes flowerPulse {
  0% {
    transform: scale(1);
    opacity: 0.9;
  }
  50% {
    transform: scale(1.1);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0.9;
  }
}

.flower-icon {
  --flower-color: var(--hex-color);
  transform-origin: center;
  filter: drop-shadow(0 0 2px var(--hex-color));
  opacity: 0;
  animation: 
    flowerGrow 0.5s ease-out forwards,
    flowerPulse 2s ease-in-out infinite;
  animation-delay: 0s, 0.5s;
}