body {
  background-color: #1c1f24;
  color: #ffffff;
  font-family: Arial, sans-serif;
}

.mines-game-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  gap: 20px;
  position: relative;
  margin-bottom: 30px;
  margin-top: 10px;
  @media (max-width: 600px) {
    flex-direction: column; /* Stack UI and game grid vertically on smaller screens */
  }
}

.mines-game-ui {
  background-color: #2a2d33;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 250px;
  flex-shrink: 0;
}

.mines-mode-toggle {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.mines-mode-btn {
  background-color: #3c3f45;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin: 0 5px;
  transition: background-color 0.3s;
}

.mines-mode-btn.active {
  background-color: #2196f3;
}

.mines-controls {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
}

.mines-control {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.mines-control label {
  margin-right: 10px;
}

.mines-control input, .mines-control select {
  background-color: #3c3c3c;
  color: #ffffff;
  border: none;
  padding: 5px 10px;
  margin-right: 10px;
  border-radius: 5px;
}

.mines-control button {
  background-color: #4caf50;
  color: #ffffff;
  border: none;
  padding: 5px 10px;
  margin-left: 5px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.mines-control button:hover {
  background-color: #45a049;
}

.mines-random-tile-btn {
  @apply bg-accent-blue;
  color: white;
  padding: 10px;
  margin-top: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

/* Use media query to disable hover effects on touch devices */
@media (hover: hover) and (pointer: fine) {
  .mines-random-tile-btn:hover {
    @apply bg-accent-blue-hover;
  }
}

.mines-random-tile-btn:active {
  @apply bg-accent-blue-hover;
}

.mines-random-tile-btn:disabled {
  background-color: #555;
  cursor: not-allowed;
}

.mines-start-btn {
  @apply bg-green-500;
  padding: 10px 20px;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 100%;
}

.mines-start-btn:disabled {
  background-color: #555;
  cursor: not-allowed;
}

/* Use media query to disable hover effects on touch devices */
@media (hover: hover) and (pointer: fine) {
  .mines-start-btn:hover:not(:disabled) {
    @apply bg-green-600;
  }
}

.mines-start-btn:active:not(:disabled) {
  @apply bg-green-600;
}

.multiplier-display {
  font-size: 20px;
  margin-bottom: 10px;
}

.mines-game-grid-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.mines-game-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 10px;
  /* Responsive adjustments */
  @media (max-width: 600px) {
    gap: 5px;
  }
}

.mines-tile {
  width: 100px;
  height: 100px;
  background-color: #333;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  cursor: pointer;
  border-radius: 10px;
  transition: background-color 0.3s;
  /* Responsive adjustments */
  @media (max-width: 600px) {
    width: 60px; /* Smaller tiles on smaller screens */
    height: 60px;
    font-size: 18px;
  }
}

.mines-tile.revealed {
  background-color: #222;
}

.mines-tile:hover {
  transform: scale(1.05) translateY(-3px);
  box-shadow: 0 1px 8px #fbfdc1;
}

.mines-icon {
  font-size: 60px;
}

.mines-diamond-icon {
  color: #00e676;
  animation: gemGrow 0.5s ease-out forwards;
  transform-origin: center;
  filter: drop-shadow(0 0 12px rgba(0, 230, 118, 0.6));
}

.mines-mine-icon {
  color: #ff1744;
  animation: gemGrow 0.3s ease-out forwards;
  transform-origin: center;
}

.mines-game-over {
  font-size: 24px;
  color: red;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  z-index: 10;
}

.mines-tile.selected {
  border: 3px solid gold;
}

.mines-tile.bomb-selected {
  border: 3px solid red; /* Red border for selected bomb tiles */
}

.mines-tile.auto-selected {
  border: 3px solid gold;
}

.mines-tile.auto-selectable {
  cursor: pointer;
}

.mines-tile.auto-selectable:hover {
  background-color: rgba(255, 215, 0, 0.2); /* Light gold background on hover */
}

@keyframes shake {
  0%, 100% {
    transform: translateX(0);
  }
  10%, 30%, 50%, 70%, 90% {
    transform: translateX(-10px);
  }
  20%, 40%, 60%, 80% {
    transform: translateX(10px);
  }
}

.shake-animation {
  animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
}

.pulsing-text {
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

@keyframes gemGrow {
  0% {
    transform: scale(0.3);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes glowPulse {
  0% {
    filter: drop-shadow(0 0 8px rgba(0, 230, 118, 0.6));
  }
  50% {
    filter: drop-shadow(0 0 12px rgba(0, 230, 118, 0.8));
  }
  100% {
    filter: drop-shadow(0 0 8px rgba(0, 230, 118, 0.6));
  }
}

/* @keyframes glowPulseMine {
  0% {
    filter: drop-shadow(0 0 8px rgba(255, 23, 68, 0.6));
  }
  50% {
    filter: drop-shadow(0 0 12px rgba(255, 23, 68, 0.8));
  }
  100% {
    filter: drop-shadow(0 0 8px rgba(255, 23, 68, 0.6));
  }
} */

.mines-diamond-icon {
  color: #00e676;
  animation: 
    gemGrow 0.3s ease-out forwards,
    glowPulse 2s ease-in-out infinite;
  transform-origin: center;
}

.mines-mine-icon {
  color: #ff1744;
  animation: 
    gemGrow 0.3s ease-out forwards,
    glowPulseMine 2s ease-in-out infinite;
  transform-origin: center;
}