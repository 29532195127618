.keno-container {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 20px;
  background-color: #2a2d33;
  border: 2px solid #ffd700;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.keno-grid {
  padding: 20px;
  background-color: #1c1f24;
  border-radius: 10px;
  overflow: hidden;
  width: 800px;
}

.keno-grid-inner {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  gap: 15px;
  position: relative;
}

.keno-number {
  position: relative;
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #333;
  color: white;
  font-size: 24px;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  user-select: none;
  transform-origin: center;
}

.keno-number:hover:not(.selected):not(.drawn) {
  transform: scale(1.05) translateY(-3px);
  box-shadow: 0 1px 8px #fbfdc1;
}

.keno-number.selected {
  background-color: #efbf04;
  color: #000000;
  border: 2px solid #efbf04;
}

.keno-number.drawn:not(.hit) {
  background-color: #222;
  color: #666;
}

.keno-number.current-draw:not(.hit) {
  animation: drawRevealBrick 1s ease-out forwards;
}

.keno-number.current-draw.hit {
  animation: drawRevealHit 1s ease-out forwards;
}

.keno-number.hit {
  position: relative;
  background-color: #333;
  color: white;
  border: 2px solid #ffd700;
  box-shadow: 0 0 15px rgba(255, 215, 0, 0.3);
}

.hit-gem {
  position: absolute;
  color: #00e676;
  font-size: 54px;
  filter: drop-shadow(0 0 8px rgba(0, 230, 118, 0.6));
  animation: gemReveal 0.3s ease-out forwards;
}

@keyframes gemReveal {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.keno-controls {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
  max-width: 400px;
}

.bet-controls {
  display: flex;
  gap: 10px;
}

.bet-controls input {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: none;
  background-color: #3c3c3c;
  color: white;
}

.game-controls {
  display: flex;
  gap: 10px;
}

.keno-button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  flex: 1;
}

.start-button {
  background-color: #4caf50;
  color: white;
}

.start-button:hover:not(:disabled) {
  background-color: #45a049;
}

.reset-button {
  background-color: #f44336;
  color: white;
}

.reset-button:hover:not(:disabled) {
  background-color: #d32f2f;
}

.selection-info {
  text-align: center;
  font-size: 18px;
  color: #fff;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes drawRevealBrick {
  0% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes drawRevealHit {
  0% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes shuffle {
  0% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(-2px);
  }
  75% {
    transform: translateY(2px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes hitPulse {
  0% {
    box-shadow: 0 0 15px rgba(255, 215, 0, 0.5);
  }
  50% {
    box-shadow: 0 0 25px rgba(255, 215, 0, 0.7);
  }
  100% {
    box-shadow: 0 0 15px rgba(255, 215, 0, 0.5);
  }
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .keno-grid {
    width: 100%;
    max-width: 320px;
    padding: 8px;
    margin: 0 auto;
  }

  .keno-grid-inner {
    grid-template-columns: repeat(5, 1fr);
    gap: 3px;
  }

  .keno-number {
    width: 34px;
    height: 34px;
    font-size: 14px;
  }

  .hit-gem {
    font-size: 26px;
  }

  .brick-x {
    font-size: 20px;
  }
}

@media (max-width: 640px) {
  .keno-grid-inner {
    grid-template-columns: repeat(6, 1fr);
    gap: 3px;
  }

  .keno-number {
    width: 34px;
    height: 34px;
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .keno-grid {
    max-width: 280px;
    padding: 6px;
  }

  .keno-grid-inner {
    grid-template-columns: repeat(4, 1fr);
    gap: 3px;
  }

  .keno-number {
    width: 32px;
    height: 32px;
    font-size: 13px;
  }

  .hit-gem {
    font-size: 22px;
  }

  .brick-x {
    font-size: 16px;
  }
}

@media (max-width: 360px) {
  .keno-grid {
    max-width: 240px;
    padding: 4px;
  }

  .keno-grid-inner {
    grid-template-columns: repeat(4, 1fr);
    gap: 2px;
  }

  .keno-number {
    width: 28px;
    height: 28px;
    font-size: 12px;
  }

  .hit-gem {
    font-size: 20px;
  }

  .brick-x {
    font-size: 14px;
  }
}

.keno-number.current-draw {
  animation: drawReveal 0.5s ease-out;
}

.draw-indicator {
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: #ff9800;
  color: white;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.keno-number.drawing-in-progress {
  transition: all 0.3s ease;
}

.keno-number.drawing-in-progress:not(.drawn) {
  animation: shuffle 2s infinite;
}

.brick-x {
  position: absolute;
  color: #ff4444;
  font-size: 32px;
  animation: xReveal 1s ease-out forwards;
  z-index: 1;
}

.number-text {
  position: relative;
  z-index: 0;
  font-weight: bold;
  font-size: 28px;
}

@keyframes xReveal {
  0% {
    transform: scale(0) rotate(-180deg);
    opacity: 0;
  }
  100% {
    transform: scale(1) rotate(0);
    opacity: 1;
  }
}
