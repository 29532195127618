/* styles.css */
.gold-tile{
  box-shadow: 0 0 8px #fff; /* Add a white glow effect */
  transition: color 0.3s, box-shadow 0.3s, transform 0.3s; /* Include transform in the transition */
  background-color: rgb(234 179 8)
}
.selected-chip{
  transform: scale(1.1);
  box-shadow: 0 0 8px #fff; /* Add a white glow effect */
}
.red-tile:hover {
  transform: scale(1.05); /* Corrected scaling effect */
  box-shadow: 0 0 8px #fff; /* Add a white glow effect */
  transition: color 0.3s, box-shadow 0.3s, transform 0.3s; /* Include transform in the transition */
  z-index: 10;
  background-color: rgb(239, 68, 68);
}
.black-tile:hover {
  transform: scale(1.05); /* Corrected scaling effect */
  box-shadow: 0 0 8px #fff; /* Add a white glow effect */
  transition: color 0.3s, box-shadow 0.3s, transform 0.3s; /* Include transform in the transition */
  z-index: 10;
  background-color: rgb(30 41 59);
}

.green-tile:hover {
  transform: scale(1.05); /* Corrected scaling effect */
  box-shadow: 0 0 8px #fff; /* Add a white glow effect */
  transition: color 0.3s, box-shadow 0.3s, transform 0.3s; /* Include transform in the transition */
  z-index: 10;
  background-color: rgb(34 197 94);
}

.grey-tile:hover {
  transform: scale(1.05); /* Corrected scaling effect */
  box-shadow: 0 0 8px #fff; /* Add a white glow effect */
  transition: color 0.3s, box-shadow 0.3s, transform 0.3s; /* Include transform in the transition */
  z-index: 10;
  background-color: rgb(128, 128, 128);
}

button {
  font-size: 1.25rem; /* Increase by 2 units */
}

html, body {
    height: 100%;
    overflow: hidden;
}

#spinny-ball, [id^="ball"] {
  background-image: radial-gradient(circle at 20% 20%, #fff, #bbb);
  box-shadow: 0 0 12px rgba(255, 255, 255, 0.8);
  border: 2px solid #ddd;
}

.hover-first-row-active {
  box-shadow: inset 0 0 10px rgba(255, 255, 255, 0.8);  /* Inner glow */
  transform: scale(1.05);  /* Slightly larger scale */
  transition: box-shadow 0.3s ease, transform 0.3s ease;
}
/* Add this to your CSS file */
.fullscreen-like {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  background-color: #000; /* or whatever background color you prefer */
  overflow: auto;
}

/* Hide Safari UI in fullscreen-like mode */
.fullscreen-like {
  -webkit-user-select: none;
  -webkit-touch-callout: none;
}

/* Add this to your CSS file, e.g., Roulette.css */
@media (max-width: 1024px) and (max-height: 1024px) {
  .mobile-only {
    display: block;
  }
  .desktop-only {
    display: none;
  }
  
  /* Mobile portrait */
  @media (orientation: portrait) {
    .mobile-portrait {
      display: block;
    }
    .mobile-landscape {
      display: none;
    }
  }
  
  /* Mobile landscape */
  @media (orientation: landscape) {
    .mobile-landscape {
      display: block;
    }
    .mobile-portrait {
      display: none;
    }
  }
}

/* Desktop devices */
@media (min-width: 1025px), (min-height: 1025px) {
  .mobile-only,
  .mobile-portrait,
  .mobile-landscape {
    display: none;
  }
  .desktop-only {
    display: block;
  }
}