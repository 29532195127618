.slider-thumb::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 24px;
  height: 24px;
  background-image: url(../../assets/zoom_slider_thumb.png);
  background-size: cover;
  background-position: center;
  cursor: pointer;
}

.slider-thumb::-moz-range-thumb {
  width: 24px;
  height: 24px;
  background-image: url(../../assets/zoom_slider_thumb.png);
  background-size: cover;
  background-position: center;
  cursor: pointer;
}
.winnings-arrow {
  position: absolute;
  text-align: center;
  bottom: -40px; /* Changed from top to bottom */
}

.winnings-amount {
  position: relative;
  width: 50px;
  line-height: 20px;
  z-index: 2;
  background: white;
  padding: 4px 8px;
  border-radius: 4px;
  margin-top: 10px; /* Changed from margin-bottom to margin-top */
  font-weight: bold;
  display: inline-block;
}

.arrow-down {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid white; /* Changed from border-top to border-bottom */
  margin-left: 16px;
}

.marker {
  position: relative;
  top: -30px;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 20px solid #efbf04;
  filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.2));
}

.marker-label {
  position: absolute;
  top: 25px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #efbf04;
  padding: 1px 8px;
  border-radius: 5px;
  font-size: 2rem;
  font-weight: bold;
  @apply text-primary-900;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

@media (max-width: 640px) {
  .marker-label {
    font-size: 1.5rem;
    padding: 1px 6px;
  }

  .marker {
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 16px solid #efbf04;
    top: -36px;
  }

  .roll-mode-toggle {
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
    padding: 2px;
  }

  .roll-mode-toggle button {
    padding: 6px 12px;
    font-size: 0.875rem;
  }
}

datalist {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  writing-mode: horizontal-lr;
  width: 100%;
}

option {
  padding: 0;
}

input[type="range"] {
  width: 100%;
}

.box-container {
    @apply rounded-lg;
    padding: 10px;
    background: rgba(26, 26, 26, 0.9);
    cursor: default;
    text-wrap: nowrap;
}

.custom-slider .MuiSlider-markLabel,
    .custom-slider .MuiSlider-valueLabel {
      font-family: 'CustomFont', 'system-ui';
    }

.roll-mode-toggle {
  display: flex;
  gap: 8px;
  background: rgba(0, 0, 0, 0.2);
  padding: 4px;
  border-radius: 8px;
  z-index: 10;
}

.roll-mode-toggle button {
  flex: 1;
  padding: 8px 16px;
  border: none;
  border-radius: 6px;
  background: transparent;
  color: white;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  white-space: nowrap;
}

.roll-mode-toggle button.active {
  background: #efbf04;
  color: #000;
}

.roll-mode-toggle button:hover:not(.active) {
  background: rgba(239, 191, 4, 0.2);
}

.roll-mode-toggle button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}