@font-face {
    font-family: 'CustomFont';
    src: url('./fonts/runescape.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'CustomFont';
    src: url('./fonts/runescape_bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'CustomFont';
    src: url('./fonts/runescape_small.ttf') format('truetype');
    font-weight: normal;
    font-style: small-caps;
}

body, ul, ol, li, strong {
    font-family: 'CustomFont', monospace;
    font-weight: normal;
    @apply text-default;
}

body, button, input, textarea, select, strong {
    font-family: 'CustomFont', monospace;
    font-weight: bold;
    @apply text-default;
}

h1 {
    font-family: 'CustomFont', monospace;
    font-weight: bold; /* This will use the bold variant */
    letter-spacing: 0.05em;
    @apply text-default;
}

h2 {
    font-family: 'CustomFont', monospace;
    font-weight: bold; /* This will use the bold variant */
    letter-spacing: .025em;
    font-size: 1.5rem;
    @apply text-default;
}

h3 {
    font-family: 'CustomFont', monospace;
    font-weight: bold; /* This will use the bold variant */
    letter-spacing: -.025em;
    font-size: 1.75rem;
    @apply text-default;
}

text {
    @apply text-default;
}
p {
    font-family: 'CustomFont', monospace;
    font-style: small-caps; /* This will use the small-caps variant */
    font-size: 1.4rem;
    @apply text-default;
}

input, select {
    font-style: small-caps;
    @apply bg-neutral-100 placeholder-neutral-600 text-primary-800 rounded-md w-full pl-2 mb-1 focus:outline-none md:mb-2;
    @apply pl-3 p-1 md:p-2 md:pl-3 rounded-lg;
    height: 35px;
}

.rs-button {
    border: 2px solid #efbf04;
    border-radius: 0.375rem;
    color: #efbf04;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 11px;
    text-align: center;
    vertical-align: top;
    font-family: 'CustomFont', monospace;
    font-weight: bold; /* This will use the bold variant */
    letter-spacing: -.025em;
    font-size: 20px;
    @apply text-default;
}

.rs-button:hover {
    background: #080808;
    scale: 1.05;
    /* -webkit-box-shadow: 0px 0px 20px 1px rgba(129, 97, 62, 0.9);
    -moz-box-shadow: 0px 0px 20px 1px rgba(129, 97, 62, 0.9);
    box-shadow: 0px 0px 20px 1px rgba(129, 97, 62, 0.9); */
}

.rs-button-small {
    border: 2px solid #efbf04;
    border-radius: 0.375rem;
    height: 40px;
    width: 50px;
    background-size: 100% 100%;
    justify-content: center;
    align-items: center;
    color: #efbf04;
    @apply mb-1 md:mb-2;
}

.rs-button-small:hover:not(:disabled) {
    background: #080808;
    scale: 1.05;
}

.normal-button {
    @apply text-white rounded-md w-full h-10 items-center justify-center mb-2 mt-2 md:mt-6 md:mb-6;
}


.well {
    box-shadow: inset 0 2px 2px hsla(0, 0%, 0%, 0.1), 0 1px 0 hsla(0, 0%, 100%, 0.15)
}

.game-container {
    background-image: radial-gradient(circle at center, rgba(45, 45, 45, 0.95) 0%, rgba(26, 26, 26, 0.95) 100%);
    background-size: cover;
    background-position: center;
    border: 2px solid #efbf04;
    border-radius: 0.5rem;
}

.game-card {
    transform: scale(1);
    border-radius: 10px;
    transition: transform 2s ease-in-out;
    height: 18vh; /* Adjusted to use viewport height */
    width: calc(18vh * 0.75);  /* Adjusted to maintain aspect ratio */
    background-size: cover;
    background-position: center;
    cursor: pointer;
}

@media (orientation: landscape) {
    .game-card {
        height: 18vw; /* Adjust to use viewport width in landscape */
        width: calc(18vw * 0.75); /* Maintain aspect ratio */
    }
}

.game-card:hover {
    transform: scale(1.05);
    transition: transform 0.4s ease-in-out;
}

.box-container-2 {
    @apply rounded-md bg-primary-600 px-5 text-2xl mt-1;
    cursor: default;
    max-height: 5vh;
    max-width: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 0;
}

.player-count {
  @apply bg-primary-600 justify-center;
  max-height: 5vh;
  display: flex;
  align-items: center;
  font-weight: 700;
}

.betting-window {
    @apply flex flex-col px-5 rounded-lg pt-1 mb-1 min-w-[20%] md:mb-0 md:mr-7 md:pt-5;
    position: relative;
}

.main-container {
  @apply flex flex-col p-3 mb-10 min-h-[100%] min-w-[100%] relative md:flex-row md:p-12;
}

@media (max-width: 767px) {
  .main-container {
    @apply flex-col-reverse;
  }
}

.volume-control {
  width: 150px; /* Increased width to accommodate button and slider */
  height: 24px;
  display: flex;
  align-items: center;
}

.volume-button {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  padding: 0;
  margin-right: 10px; /* Add space between button and slider */
}

.volume-button svg {
  width: 24px;
  height: 24px;
}

.volume-slider {
  flex-grow: 1;
  opacity: 1;
  transition: opacity 0.3s ease;
}

.slider-root {
  color: hsla(156, 41.3%, 44.7%, 100%);
  height: 4px;
  width: 100%;
  padding: 13px 0;
  display: inline-block;
  position: relative;
  cursor: pointer;
  touch-action: none;
  -webkit-tap-highlight-color: transparent;
}

.slider-rail {
  display: block;
  position: absolute;
  width: 100%;
  height: 4px;
  border-radius: 2px;
  background-color: currentColor;
  opacity: 0.38;
}

.slider-track {
  display: block;
  position: absolute;
  height: 4px;
  border-radius: 2px;
  background-color: currentColor;
}

.slider-thumb {
  position: absolute;
  width: 14px;
  height: 14px;
  margin-left: -6px;
  margin-top: -5px;
  box-sizing: border-box;
  border-radius: 50%;
  outline: 0;
  border: 2px solid currentColor;
  background-color: #fff;
}

.slider-thumb:hover {
  box-shadow: 0 0 0 8px rgba(58, 133, 137, 0.16);
}

.slider-thumb.focus-visible {
  box-shadow: 0 0 0 6px rgba(58, 133, 137, 0.16);
}

.slider-thumb.active {
  box-shadow: 0 0 0 8px rgba(58, 133, 137, 0.16);
}

.speed-control {
  display: flex;
  align-items: center;
  justify-content: center;
}

.speed-button {
  bottom: 10px;
  right: 10px;
  background: url(./assets/button.png) no-repeat center;
  background-size: 100% 100%;
}

.speed-button:hover {
  bottom: 10px;
  right: 10px;
  background: url(./assets/button_hovered.png) no-repeat center;
  background-size: 100% 100%;
}

@keyframes moveRight {
  0% {
    left: -25%;
  }
  100% {
    left: 115%;
  }
}

.rules-container {
  @apply p-6 mx-3 mb-10 relative md:mx-12;
  background-image: radial-gradient(circle at center, rgba(45, 45, 45, 0.95) 0%, rgba(26, 26, 26, 0.95) 100%);
  border: 2px solid #efbf04;
  border-radius: 0.5rem;
  color: #e0e0e0;
}

.rules-container h3 {
  @apply text-4xl font-bold mb-4;
  color: #efbf04;
}

.rules-container h4 {
  @apply text-xl font-bold mt-6 mb-2;
  color: #efbf04;
}

.rules-container p {
  @apply mb-4 leading-relaxed;
  color: #e0e0e0;
}

.rules-container ul, .rules-container ol {
  @apply ml-6 mb-4;
  color: #e0e0e0;
}

.rules-container li {
  @apply mb-2 text-2xl;
  color: #e0e0e0;
}

.rules-container strong {
  color: #efbf04;
  @apply text-lg;
}

.moving-logo {
  animation: moveRight 30s linear infinite;
}

.toggle-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 200px;
  height: 40px;
  border: 2px solid #efbf04;
  @apply bg-primary-900;
  border-radius: 20px;
  margin-bottom: 24px;
}

.toggle-container input[type="radio"] {
  display: none;
}

.toggle-label {
  flex: 1;
  text-align: center;
  line-height: 40px;
  color: #fff;
  font-weight: bold;
  z-index: 1;
}

.toggle-slider {
  position: absolute;
  top: 3px; /* Adjusted to create a gap */
  left: 3px; /* Adjusted to create a gap */
  width: calc(50% - 6px); /* Adjusted to create a gap */
  height: calc(100% - 6px); /* Adjusted to create a gap */
  @apply bg-[#efbf04];
  border-radius: 20px;
  transition: left 0.3s;
  box-shadow: 0 0 0 3px var(--tw-bg-opacity, 1) var(--tw-bg-color, #2c3e50); /* Creates the gap */
}

input[type="radio"]#manual:checked ~ .toggle-slider {
  left: 3px; /* Adjusted to create a gap */
}

input[type="radio"]#auto:checked ~ .toggle-slider {
  left: calc(50% + 3px); /* Adjusted to create a gap */
}

.bg-enabled {
  @apply bg-green-500;
}

.bg-enabled:hover {
  @apply bg-green-600;
}

.bg-disabled {
  @apply bg-neutral-400 cursor-not-allowed;
}
@keyframes shake {
  0%, 100% {
    transform: translateX(0);
  }
  10%, 30%, 50%, 70%, 90% {
    transform: translateX(-10px);
  }
  20%, 40%, 60%, 80% {
    transform: translateX(10px);
  }
}

.countdown-timer {
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 1rem;
  color: #FFD700;
}

.leaderboard-rewards {
  margin-bottom: 1rem;
  padding: 1rem;
  background-color: hsla(24, 12.82%, 30%, 100%);
  border-radius: 8px;
}

.leaderboard-rewards h3 {
  margin-bottom: 0.5rem;
}

.leaderboard-rewards ul {
  list-style-type: none;
  padding-left: 0;
}

.leaderboard-banner {
  background-color: hsla(156, 41.3%, 25%, 100%);
  color: white;
  text-align: center;
  padding: 0.5rem;
  font-size: 1.1rem;
  font-weight: bold;
}

.leaderboard-banner a {
  color: #FFD700;
  text-decoration: none;
}

.leaderboard-banner a:hover {
  text-decoration: underline;
}

.play-again-button {
  @apply mt-4 px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 transition duration-300 flex items-center;
}