@tailwind base;
@tailwind components;
@tailwind utilities;

/* body {
  overflow-x: auto;
  background-color: #1a2c38;
} */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}

/* Custom Scrollbar Styles */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.3);
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  background: linear-gradient(45deg, #efbf04 0%, #c49b03 100%);
  border-radius: 5px;
  border: 2px solid rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(45deg, #ffd104 0%, #efbf04 100%);
}

/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #efbf04 rgba(0, 0, 0, 0.3);
}

